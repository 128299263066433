import * as React from "react";
import Block from "../components/block";
import Layout from "@/components/layout"
import { navigate } from 'gatsby';
import ChipPartOfSpeech from "@/components/chipPartOfSpeech"
import usePageBottom from "@/hooks/usePageBottom";

import { SEO } from "@/components/seo"

export const Head = ({ pageContext }) => {
  const { page } = pageContext;
  return (<SEO title={`${page.slug} ${page.title_thai}  | Prompt Dictionary`} />)
}
  

export default function PromptTemplate({ pageContext }) {
  const { page } = pageContext;
  const { betweenBottom, scrollToTop } = usePageBottom()
  return <>
    <Layout>
      <div className="py-4">
        <button onClick={() =>
          navigate('/')} className="border rounded-lg p-2 hover:opacity-70 hover:text-yellow-300">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15.125 21.1L6.7 12.7q-.15-.15-.212-.325q-.063-.175-.063-.375t.063-.375q.062-.175.212-.325l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.862q0 .513-.375.888t-.875.375q-.5 0-.875-.375Z" /></svg>
        </button>
        <div className="flex gap-2 md:flex-row flex-col mt-4">
          <h2>{page.slug || ""}</h2>
          <h4 className="font-light mt-0 md:self-end">{page.title_thai || ""}</h4>
        </div>
        <div className="flex gap-1 mt-2">
          {
            page.part_of_speechs && page.part_of_speechs.map((pos, j) =>
              <ChipPartOfSpeech key={j} content={pos}></ChipPartOfSpeech>
            )
          }
        </div>
        <hr className="mt-4" />
        {
          page.contents && page.contents.map((block, i) => {
            return (
              <Block
                key={i}
                type={block.type}
                content={block.content}
              />
            );
          })
        }
        {
          betweenBottom &&
          <button onClick={scrollToTop} className="flex items-center border rounded-lg p-4 fixed bottom-14 right-4 hover:opacity-70 bg-white hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M6.7 14.7q-.275-.275-.275-.7q0-.425.275-.7l4.6-4.6q.15-.15.325-.213q.175-.062.375-.062t.388.075q.187.075.312.2l4.6 4.6q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275L12 10.8l-3.9 3.9q-.275.275-.7.275q-.425 0-.7-.275Z" /></svg>
          </button>
        }
      </div>
    </Layout>
  </>
}

import React, { useEffect, useState } from "react";

const Block = ({ type, content }) => {
  const [domRender, setDomRender] = useState(<></>)

  function getEmbedYouTube(youtubelink) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = youtubelink.match(regExp);

    if (match && match[2].length == 11) {
      return `https://www.youtube.com/embed/${match[2]}`
    } else {
        return youtubelink
    }
  }

  useEffect(() => {
    if (type === "child_page") {
      setDomRender(<strong>***[Notion Link] : {content.title}***</strong>);
    } else if (type === "divider") {
      setDomRender(<hr className="w-full h-[1px] my-4 bg-slate-500" />)
    } else if (type === "heading_1") {
      setDomRender(<h1>{content.rich_text[0]?.text.content || ''}</h1>);
    } else if (type === "heading_2") {
      setDomRender(<h2>{content.rich_text[0]?.text.content || ''}</h2>);
    } else if (type === "heading_3") {
      setDomRender(<h3>{content.rich_text[0]?.text.content || ''}</h3>);
    } else if (type === "bulleted_list_item") {
      setDomRender(<li>{content.rich_text[0]?.text.content || ''}</li>);
    } else if (type === "image") {
      setDomRender(<div><img src={content.file?.url} alt={content.id}></img></div>);
    } else if (type === "video") {
      let domRender
      if (content.type === 'file') {
        domRender = <div>
          <video controls>
            <source src={content.file?.url} type="video/mp4" />
            <p>
              Your browser doesn't support HTML video. Here is a
              <a href={content.file?.url}>link to the video</a> instead.
            </p>
          </video>
        </div>;
      } else if (content.type === 'external') {
        domRender = <div>
          <iframe width="420" height="345" src={getEmbedYouTube(content.external.url)} frameBorder="0" allowFullScreen />
        </div>;
      }
      setDomRender(domRender)
    } else if (type === "paragraph") {
      let richTextArr = content.rich_text;
      let domRender
      if (richTextArr.length === 0) {
        domRender = <div className="pt-4"></div>;
      } else {
        domRender = richTextArr.map(item => {
          if (item.type === "text") {
            return <Block
              key={item.plain_text}
              type="text"
              content={item}></Block>
          } else if (item.type === "mention") {
            return <Block
              key={item.plain_text}
              type="mention"
              content={item}></Block>
          } else {
            return <p>*** not supoort format ***</p>;
          }
        })
        setDomRender(domRender)
      }
    } else if (type === "text") {
      setDomRender(<p>{content.plain_text}</p>)
    } else if (type === "mention") {
      setDomRender(<a href={content.href}>{content.plain_text}</a>);
    } else {
      setDomRender(<></>);
    }
  }, [content])

  return (<>{domRender}</>)
};

export default Block;
